.tooltip {
  border-radius: 10px;
  background-color: #003956f5;
  color: #ffffff;
  font-family: Outfit;
  border-style: solid;
  border-width: 2px;
  transform: translate(0, -100%);
  position: absolute;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 10px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: 0;
  width: 6.5em;
  overflow: hidden;
  white-space: nowrap;
  transition: border-color 0.4s ease, opacity 0.4s ease, width 0.4s ease;
}

.tooltip.visible {
  opacity: 1;
  width: 8.5em;
}

.tooltip p {
  font-size: 12px
}