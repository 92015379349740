@import url('https://fonts.googleapis.com/css?family=Outfit&subset=latin,latin-ext');

.App {
  background-color: #0f374f;
  height: 100vh;
  display: flex;
  flex-direction: row;
  font-family: Outfit;
  justify-content: center;

  position: relative;
  overflow: hidden;
}

.horizontal-divider-wrapper {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.horizontal-divider {
  width: 100%;
  background-color: #505670;
  height: 1px;
}

body {
  font-family: Outfit;
  color: #ffffff;
}

h1 {
  font-family: Outfit;
  font-size: 26px;
  line-height: 28px;
  margin: 0;
}

h2 {
  font-family: Outfit;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}

p {
  font-family: Outfit;
  font-size: 12.5px;
  font-weight: 400;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #0f374f inset;
  -webkit-text-fill-color: #ffffff;
}

input {
  caret-color: #ffffff;
}