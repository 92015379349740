.filter-container {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
}

.filter-selector {
  margin-left: 0em;
  margin-bottom: -0.6em;
}

.filter-slider {
  margin-top: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
}