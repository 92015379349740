.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background-color: #00000000;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib a {
  color: #b0b0b0;
}

.mapboxgl-ctrl-group {
  background-color: #000000
}

.mapboxgl-map {
  flex: 1;
  background-color: #000000
}

.sitowise-logo {
  pointer-events: none;
  width: 63px;
  position: absolute;
  left: 110px;
  bottom: 12px;
}


/* .mapboxgl-canvas-container {
  transition: width 0.3s;
} */


/* .mapboxgl-popup-content {
  border-radius: 10px;
  background: #003956;
  color: #ffffff;
  font-family: Outfit;
  pointer-events: none;
}

.mapboxgl-popup-tip {
  color: #151515;
} */