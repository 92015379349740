.energy-calculator {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  background-color: #0f374f;
  align-self: center;

  border-radius: 1em;
  border-style: solid;
  border-width: 2px;
  border-color: #175378;

  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.8);
  width: min(90vw, 60em);
  overflow: hidden;
  z-index: 99;
}

.draggable-handle:active {
  cursor: grabbing;
}