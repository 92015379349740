.area-info-panel {
  transition: opacity 0.3s ease-in-out;
  top: 65px;
  right: 0;
  position: absolute;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  background: #0f374f;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
  padding: 1em;
  max-height: calc(100vh - 2em - 63px - 32px);
  color: #ffffff;
  width: min(55vw, 19em);
  border-style: solid;
  border-width: 2px;
  border-color: #175378;
  border-right-width: 0;
  overflow: auto;
}

.area-info-panel-transparent {
  opacity: 0;
  pointer-events: none;
}

.area-info-header {
  white-space: pre-line;
  /* or white-space: pre-wrap; */
  /* Additional styling if needed */
}

.chart-title-and-selector {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}