.mui-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  width: min(26rem, 90vw);
}


/* TODO input:-moz-autofill */