.energiakartta-title-div {
  /* color: #000000; */
  /* margin-bottom: 2em; */

  padding-left: 1.2em;
  padding-right: 1.2em;
  padding-top: 0.3em;
  padding-bottom: 0.5em;

  border-width: 2px;
  border-style: solid;
  border-radius: 14px;
  /* 
  box-shadow: 0px 15px 15px #0f374fff; */
  z-index: 99;
}

.left-panel {
  display: flex;
  flex-direction: column;
  background: #0f374f;
  /* transition: width 0.4s; */
  overflow: visible;

  border-right-style: solid;
  border-right-width: 2px;
  border-right-color: #175378;
  padding: 1.2em;
  width: min(55vw, calc(16em));
  overflow: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.left-panel-collapsed {
  display: flex;
  width: 0px;
  flex-direction: column;
  background: #0f374f;
  /* transition: width 0.4s; */
  overflow: hidden;
}

.collapse-button {
  position: absolute;
  top: 0.6em;
  left: min(calc(55vw + 3.2em), calc(16em + 3.2em));
  /* transition: left 0.4s; */
  background-color: #0f374f;
  border-radius: 0.9em;
  cursor: pointer;
  z-index: 99;
}

.collapse-button-collapsed {
  position: absolute;
  top: 0.6em;
  left: 0.6em;
  /* transition: left 0.4s; */
  background-color: #0f374f;
  border-radius: 0.9em;
  padding: 0em;
  cursor: pointer;
  z-index: 99;
}

/* .top-left-radius {
  position: absolute;
  top: 0px;
  right: -28px;
  z-index: 99;
}

.bottom-left-radius {
  position: absolute;
  bottom: 0px;
  right: -28px;
  z-index: 99;
}

.top-right-radius {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
}

.bottom-right-radius {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 99;
} */