.header-wrapper {
  font-family: "Outfit";
  position: absolute;
  top: 0px;
  right: 0px;
  height: 44px;
  z-index: 99;
  display: flex;
  flex-direction: row;
}

.header-main {
  background-color: #0f374f;
  display: flex;
  flex-direction: row;
  height: 100%;
  border-bottom-left-radius: 1.5em;
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: #175378;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #175378;
}

.loading,
.error {
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  background: #0f374f;
  padding: 8px;
  border-radius: 1em;
}

.search-results {
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  background: #0f374f;
  border-style: solid;
  border-width: 2px;
  border-color: #175378;
  max-height: 350px;
  overflow-y: auto;
  list-style: none;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  border-radius: 1em;
}

.search-results li {
  padding: 8px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #264558;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #0f374f inset;
  -webkit-text-fill-color: #ffffff;
} */