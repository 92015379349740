.admin-dashboard {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center; */
}