.building-info-container {
  transition: opacity 0.3s ease-in-out;
  top: 63px;
  right: 0;
  position: absolute;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  background: #0f374f;
  color: #ffffff;
  padding: 1em;
  max-height: calc(100vh - 2em - 63px - 32px);
  width: min(55vw, 19em);
  border-style: solid;
  border-width: 2px;
  border-color: #175378;
  border-right-width: 0;
  overflow: auto;
}

.building-info-container-transparent {
  opacity: 0;
  pointer-events: none;
}

/* Unvisited link */
a:link {
  color: rgb(255, 0, 221);
}

/* Visited link */
a:visited {
  color: rgb(255, 0, 221);
}

/* On mouse hover */
a:hover {
  color: rgb(255, 141, 240);
}

/* On active (while clicking) */
a:active {
  color: rgb(255, 178, 245);
}