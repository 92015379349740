.circular-progress {
  transition: opacity 0.3s ease-in-out;
  /* background-color: #0f374f; */
  z-index: 99;
  padding: 0.4rem;
  margin-right: 0.8em;
  /* border-radius: 0.7rem; */
  pointer-events: none;
}

.circular-progress-transparent {
  transition: opacity 0.3s ease-in-out;
  /* background-color: #0f374f; */
  z-index: 99;
  opacity: 0;
  padding: 0.4rem;
  margin-right: 0.8em;
  /* border-radius: 0.7rem; */
  pointer-events: none;
}

.error {
  background-color: #0f374f;
  border-color: #175378;
  border-style: solid;
  border-width: 2px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  align-self: center;
  height: 100%;
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 12px;
}